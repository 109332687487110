nav {
  min-height: 10vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  button {
    background: transparent;
    border: none;
    cursor: pointer;
    border: 1px solid indianred;
    padding: 0.8rem;
    transition: all 0.3s ease-in-out;
    &:hover {
      background: indianred;
      color: white;
    }
  }
  h1 {
    font-size: 2rem;
  }
}
