.library {
  position: fixed;
  top: 0;
  left: 0;
  width: 25rem;
  height: 100%;
  box-shadow: 2px 2px 50px rgb(143, 135, 135);
  overflow: scroll;
  transform: translateX(-100%);
  transition: all 0.5s ease-in-out;
  opacity: 0;
  h2 {
    padding: 2rem;
    font-size: 1.5rem;
  }
}
.librarySong {
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  cursor: pointer;
  img {
    width: 30%;
  }
  h3,
  h4 {
    padding-left: 1rem;
  }
  &:hover {
    background-color: lightgray;
  }
}

.song-description {
  padding-left: 1rem;
  h3 {
    font-size: 1rem;
  }
  h4 {
    font-size: 0.7rem;
  }
}

* {
  scrollbar-width: thin;
  scrollbar-color: lightgray;
}

*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 20px;
  border: transparent;
}

.selected {
  background: rgb(243, 160, 128);
}

.active {
  transform: translateX(0%);
  opacity: 1;
}
