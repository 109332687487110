.player {
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.time-controler {
  width: 50%;
  display: flex;
  align-items: center;
  input {
    width: 100%;
    cursor: pointer;
    -webkit-appearance: none;
    background-color: transparent;
    cursor: pointer;
  }
  p {
    padding: 1rem;
    font-size: 0.8rem;
    font-weight: bold;
  }
}

.play-control {
  width: 30%;
  display: flex;
  justify-content: space-between;
  svg {
    cursor: pointer;
  }
}

input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
}
input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  outline: transparent;
  height: 16px;
  width: 16px;
}

.track {
  background-color: lightsalmon;
  width: 80%;
  height: 1rem;
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
}
.animate-track {
  background-color: lightgray;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0%;
  left: 0%;
  transform: translateX(0%);
  pointer-events: none;
}
